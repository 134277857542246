import { action, observable, toJS } from 'mobx';
import { removeItem, addItem } from '../utils/array';

class CheckBoxStore {
  @observable imageWithPrice = false;
  @observable imageWithoutPrice = false;
  @observable isDelete = false;
  @observable valueCheckBox = [];
  @observable count = 0;

  @action setCount = async (number) => {
    this.count = number;
  }

  @action del = async v => {
    this.isDelete = v;
  };

  @action getAll = async () => {
    this.imageWithPrice = true;
    this.imageWithoutPrice = true;
  }


  @action getWithPrice = async v => {
    this.imageWithPrice = v;
    this.imageWithoutPrice = false;
  };

  @action getWithoutPrice = async v => {
    this.imageWithPrice = false;
    this.imageWithoutPrice = v;
  };

  @action cancel = async () => {
    this.imageWithPrice = false;
    this.imageWithoutPrice = false;
    this.count = 0;
  };

  @action setValue = async (value, multi) => {
    if (
      this.valueCheckBox.length > 0 &&
      typeof this.valueCheckBox[0] != typeof value
    ) {
      this.valueCheckBox = [];
      this.count = 0;
    }

    if (multi) {
      this.valueCheckBox = addItem(this.valueCheckBox, value);
    } else {
      this.valueCheckBox = value;
    }
    this.count = this.valueCheckBox.length;
  };

  @action removeValue = async value => {
    const newArray = removeItem(this.valueCheckBox, value);
    this.valueCheckBox = toJS(newArray);
    this.count = this.count - 1;
  };

  @action reset = async () => {
    this.imageWithPrice = false;
    this.imageWithoutPrice = false;
    this.valueCheckBox = [];
    this.count = 0;
    this.isDelete = false;
  };
}

export default CheckBoxStore;
