import CheckBoxStore from './checkBoxStore';
import Alert from './alert';
import Modal from './modal';
import Url from './urlStore';
class ApplicationStore {
  constructor() {
    this.checkBox = new CheckBoxStore();
    this.alert =  new Alert();
    this.modal = new Modal();
    this.Url = new Url();
  }
}

const appStore = new ApplicationStore();
export default appStore;
