import * as types from '../../commons/constants';

let initialState = {
    totalPrice: 0,
    totalProfit: 0,
    products: [],
    errorPrice: false,
}

let editOrderNew = (state = initialState, action) => {
    switch(action.type){
        case types.TOTAL_PRICE:
            return {
                ...state,
                totalPrice: action.totalPrice
            }

        case types.TOTAL_PROFIT:
            return {
                ...state,
                totalProfit: action.totalProfit
            }

        case types.PRODUCT_IN_ORDER_NEW:
            return {
                ...state,
                products: action.products
            }

        case types.ERROR_PRICE:
            return {
                ...state,
                errorPrice: action.error
            }
        default: return state
    }
}

export default editOrderNew;