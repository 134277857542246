// const COREAPI = 'https://demo.nhanhre.vn/api/';
// const API = 'http://demo.elara.vn/api/';
// const MYAPI = 'https://my.nhanhre.vn/api/';
// const APISHOP ='https://my.shop.shopping.com/api/';
// const API_TOKEN = '123';
// const API_SEARCH = 'http://demoapi.beshop.vn/api/';

const COREAPI =
  process.env.REACT_APP_CORE_API || 'https://demoshop.nhanhre.vn/api/';
const API = process.env.REACT_APP_API || 'http://demo.nhanhre.vn/api/';
const MYAPI = process.env.REACT_APP_MY_API || 'http://my.nhanhre.vn/api/';
const APISHOP =
  process.env.REACT_APP_SHOP_API || 'https://my.shop.shopping.com/api/';
const API_TOKEN = process.env.REACT_APP_API_TOKEN || '123';
const API_SEARCH =
  process.env.REACT_APP_API_SEARCH || 'http://demoapi.beshop.vn/api/';

const api = {
  api_token: API_TOKEN,
  domain: '/',
  //media
  placeholder: 'https://localhost:3000/img/placeholder.png',
  //Catagories
  token:
    '8DEE8XHMJuIDCSf8wRxoCrcbzEkbcRx9FeJ5fQP8C4WyyWFkSkNzA5PnQ56aH8WyeLl6IcDTJA5kqCoyMkmdfRzLjElHiXKZLbId',
  categories: COREAPI + 'category',
  getTitleCategory: COREAPI + 'category-tree/',

  upLoadBannerCategory: COREAPI + 'collaborator/new/upload-category-image',
  uploadAvatarCategory: COREAPI + 'collaborator/new/update-category-avatar',
  upLoadChildBannerCategory:
    COREAPI + 'collaborator/new/upload-collaborator-children-category-image',
  uploadBannerInCategory: COREAPI + 'collaborator/new/update-category-banner',
  getBannerCategoryUploaded:
    COREAPI + 'collaborator/new/collaborator-category-image',
  getBannerChildCategoryUploaded:
    COREAPI + 'collaborator/new/get-collaborator-children-category-image',
  updateImagesCategory: COREAPI + 'collaborator/new/update-category-image-v2',
  changeLocationCategory:
    COREAPI + 'collaborator/new/update-user-category-priority',
  getBannerForCategoryPage:
    COREAPI + 'collaborator/new/get-all-category-banner',
  chooseBannerCategoryUploaded:
    COREAPI + 'collaborator/new/choose-category-banner',
  getPermisionOfCTV: COREAPI + 'collaborator/new/staff-roles',
  getStockInfo: API + 'stock-info',
  getConfigBeshop: COREAPI + 'appConfig',

  //analysis
  customerAnalysis: API + 'collaborator/customer-analytics',
  orderAnalysis: API + 'collaborator/order-analytics',
  productAnalysis: API + 'collaborator/product-analytics',

  //manage staff
  getListStaff: COREAPI + 'collaborator/new/list-staff',
  createStaff: COREAPI + 'collaborator/new/create-staff',
  deleteStaff: COREAPI + 'collaborator/new/delete-staff',
  updateStaff: COREAPI + 'collaborator/new/update-staff-v2',
  actionStaff: COREAPI + 'collaborator/new/staff-action',

  //manage whole sale customer
  getStatusWholeSaleCustomer:
    COREAPI + 'collaborator/new/get-show-price-whole-sale',
  getListWholeSaleCustomer:
    COREAPI + 'collaborator/new/get-list-customer-whole-sale',
  updateStatusWholeSaleCustomer:
    COREAPI + 'collaborator/new/show-price-whole-sale',
  updatePasswordForWholeSaleCustomer:
    COREAPI + 'collaborator/new/update-password-customer-whole-sale',
  createWholeSaleCustomer:
    COREAPI + 'collaborator/new/create-customer-whole-sale',

  //git info customer by phone & report
  getInfoOrderByPhone: COREAPI + 'collaborator/new/check-report-info-phone/',
  reportPhoneOfCustomer: COREAPI + 'collaborator/new/report-phone',

  //tags
  createTag: COREAPI + 'collaborator/new/create-tag',
  getTags: COREAPI + 'collaborator/new/get-tag-list',
  addTagToOrder: API + 'collaborator/add-tag-order',
  removeTagInOrder: API + 'collaborator/remove-tag-in-order',

  //Product
  careProduct: COREAPI + 'product-v1',
  popularProduct: COREAPI + 'product-v1',
  product_v2: COREAPI + 'collaborator/new/list-product-code',
  product_vote: COREAPI + 'voting-product',
  product_voting: COREAPI + 'collaborator/new/voting-product',
  detail_product_vote: COREAPI + 'voting-product/',
  product_vote_of_colla: COREAPI + 'collaborator/new/voting-product',
  getProductsSeen: COREAPI + 'list-product-seen-v2',
  addSeenProduct: COREAPI + 'add-seen-product',
  getListProductsSeen: COREAPI + 'seen-product-list',
  updateOldOrder: API + 'collaborator/update-product-to-old-order',
  getSimilarProduct: API_SEARCH + 'detail-with-relate/',

  //manage
  getStoreImages: COREAPI + 'inventory-image',

  //get address
  getAddressReturnOrder: COREAPI + 'receive-address-info',

  //'bestSaleProduct': server + 'product-v1?filter=order&limit=50&page=',

  //'productOnCat_BestSale': server + 'product-v1?filter=order&limit=50&categoryCode=',
  productOnCat_Popular:
    COREAPI + 'product-v1?filter=view&limit=50&categoryCode=',
  productOnCat_Care:
    COREAPI + 'product-v1?filter=in-cart&limit=50&categoryCode=',
  detailProduct: COREAPI + 'product-v2/',
  detailProduct_v3: COREAPI + 'product-detail-v4/',
  getStock: COREAPI + 'getStock?',
  getProducts: COREAPI + 'product-v3',
  domain_product: COREAPI + 'domain-product-ver3',

  //product require and vote
  getProductForVote: COREAPI + 'system-product-vote',
  getSizeProductForVote: COREAPI + 'system-product-vote-size/',
  productVoteStock: COREAPI + 'collaborator/product-vote/',
  productRequestStock: COREAPI + 'collaborator/product-request-stock/',
  productRequestStockFilter:
    COREAPI + 'collaborator/product-request-stock-filter',
  getListProductRequireStock: COREAPI + 'collaborator/product-request-stock',
  productVotetStockFilter: COREAPI + 'collaborator/product-vote-filter',
  getListProductVoteStock: COREAPI + 'collaborator/product-vote',
  getDetailProductRequestStock: COREAPI + 'collaborator/product-request-stock/',
  updateDetailProductRequestStock:
    COREAPI + 'collaborator/update-request-stock/',
  getDetailProductVoteStock: COREAPI + 'collaborator/product-vote/',
  updateDetailProductVoteStock: COREAPI + 'collaborator/update-vote/',
  getDataDetailPageVoteProduct: COREAPI + 'system-product-vote/',

  //management list product 2023
  getListProductManagement:
    COREAPI + 'collaborator/new/shop-product-management',
  getListInStock: COREAPI + 'collaborator/new/shop-product-in-stock',
  getListProductProcessing:
    COREAPI + 'collaborator/new/shop-product-management',
  instockByProductCode: COREAPI + 'collaborator/new/shop-product-in-stock',
  getDetailStock: COREAPI + 'collaborator/new/shop-product-stock',

  //profit product per month
  getListProfitProductPerMonth:
    COREAPI + 'collaborator/new/commission-amount-month-statistic',
  getDetailProfitProduct:
    COREAPI + 'collaborator/new/commission-amount-month-statistic/',
  getTransactionProfit:
    COREAPI + 'collaborator/new/transaction-by-collaborator/',

  //API domain
  tinhv4: COREAPI + 'domain-partner/province',
  huyenv3: COREAPI + 'domain-partner/district/',
  phuongv3: COREAPI + 'domain-partner/ward/',

  tinhv3: COREAPI + 'domain-partner/location',
  tinh: API + 'province',
  tinhv2: COREAPI + 'transporter/province',

  huyen: API + 'district/',
  huyenV2: COREAPI + 'transporter/district',

  wardV2: COREAPI + 'transporter/ward',

  vanchuyen: COREAPI + 'shipping-fee?',
  vanchuyen2: COREAPI + 'shipping-fee-default?',
  getSubDistrict: COREAPI + 'jte/get-address',
  // createOrder: API +  "collaborator/order-v3",
  createOrder: API + 'collaborator/collaborator-create-order-v2',
  createOrderV3: API + 'collaborator/collaborator-create-order-v3',
  checkAmount: APISHOP + 'getStock',

  //manage order
  getListRefundDomain: COREAPI + 'collaborator/new/refund-domain',
  getListOrder: API + 'collaborator/new/get-list-order',
  getListOrderV2: API + 'collaborator/new/get-list-order-ver-4',
  getProductById: COREAPI + 'getProductDetail/',
  detailOrder: API + 'collaborator/order-v2/',
  checkStock: COREAPI + 'getStock',
  cancelOrder: API + 'collaborator/order/cancel-v3/',
  getPackFee: COREAPI + 'get-pack-fee/',
  getProfitDetail: COREAPI + 'collaborator/new/profit-detail',
  validateAddOrder: COREAPI + 'collaborator/new/validate-add-order',
  createOrderPartner: API + 'collaborator/partner-order-v3',
  partner: API + 'partner-id',
  returnOrder: API + 'collaborator/order-return',
  updateOrderPartnerTransport:
    API + 'collaborator/order/update-partner-transport/',
  updateTotalMoney: API + 'collaborator/update-total-money',
  getPartnerTransport: COREAPI + 'get-partner-transports',
  updateNoteTransport: COREAPI + 'collaborator/new/transport-note',
  getOrderWaitReturn: COREAPI + 'collaborator/new/request-exchange-order-v2/',
  returnOrder_v2: API + 'collaborator/order-return-v3',
  getExchangeReasons: COREAPI + 'get-exchange-reasons',
  editCollaboratorShip: API + 'collaborator/update-collaborator-ship',
  getPolicies: COREAPI + 'policies',
  removeProductInOrder: API + 'collaborator/remove-product-from-old-order',
  addProductInOrder: API + 'collaborator/add-product-to-old-order-v2',
  removeLackProductInOrder: API + 'collaborator/remove-product-in-lack-order',
  uploadFileBillTMDT: API + 'collaborator/order-partner-bill',
  getTransporterActive: API + 'transporter',

  //manage transition
  create_otp_withdraw: COREAPI + 'collaborator/new/create-otp-request-withdraw',
  request_withdraw: COREAPI + 'collaborator/new/request-withdraw',
  transition: COREAPI + 'collaborator/new/transaction-by-date',
  banklist: COREAPI + 'collaborator/new/get-bank-account',
  balance_request: COREAPI + 'collaborator/new/get-current-balance-request-out',
  current_balance: COREAPI + 'collaborator/new/get-current-balance',
  addBank: COREAPI + 'collaborator/new/create-collaborator-bank-account',
  requestWithDraw: COREAPI + 'collaborator/new/request-balance-out-v5',
  createOTP: COREAPI + 'collaborator/new/create-otp',
  verifyOTP: COREAPI + 'collaborator/new/check-otp',
  getListDeposit: COREAPI + 'collaborator/new/request-add-balance',
  deposit: COREAPI + 'collaborator/new/request-add-balance',
  getListTranSaction: COREAPI + 'collaborator/new/transaction-by-collaborator',

  //authentication
  login: COREAPI + 'collaborator/new/phone-login',
  verifyPassword: COREAPI + 'collaborator/new/check-password-v2',
  register: COREAPI + 'collaborator/new/register-v3',
  changePassword: COREAPI + 'collaborator/new/update-password',
  addNewPassword: COREAPI + 'collaborator/new/add-password',
  forgetPass: COREAPI + 'collaborator/new/forgot-password',
  newPassword: COREAPI + 'auth/set-new-require-password',

  getSiteinfo: API + 'collaborator/new/website-info',
  updateSiteinfo: API + 'collaborator/new/update-website-info',
  domainInfo: API + 'collaborator/new/website-collaborator-info',
  updateDomainInfo: API + 'collaborator/new/update-website-collaborator-info',
  getInfomationOwner: API + 'collaborator/new/website-collaborator-info',
  updateInfomationOwner:
    API + 'collaborator/new/update-website-collaborator-info',
  loginOwnerShop: COREAPI + 'collaborator/new/login-staff-v2',
  userProfile: COREAPI + 'collaborator/new/getInfo',
  getPhoneReceiveOTP: COREAPI + 'collaborator/new/get-phone-otp',
  updatePhoneReceiveOTP: COREAPI + 'collaborator/new/update-phone-otp-v2',
  createPhoneReceiveOTP:
    COREAPI + 'collaborator/new/create-otp-change-otp-phone',

  //search
  search: COREAPI + 'getProductDetail-v3/',
  elasticSearch: API_SEARCH + 'search',

  //v2 không check stock
  searchv2: COREAPI + 'getProductDetail-v4/',

  //user
  userInfo: COREAPI + 'collaborator/new/user-profile',
  updateUserInfo: COREAPI + 'collaborator/new/update-profile',
  //seller
  shopInfo: COREAPI + 'collaborator/new/shop-info',
  updateShopInfo: COREAPI + 'collaborator/new/update-shop-info',

  //campaign
  listCampaign: COREAPI + 'current-activated-campaign',
  listCampaignActive: COREAPI + 'current-activated-campaign-v2',

  //trending_view
  trendingView: COREAPI + 'collaborator/new/update-trending',
  //info deposit
  infoDeposit: COREAPI + 'system-bank-account-information',
  allShippingFee: COREAPI + 'collaborator/new/all-shipping-fee',
  updateShippingFee: COREAPI + 'collaborator/new/update-shipping-fee',

  //website
  updateAttributeCategory:
    COREAPI + 'collaborator/new/update-collaborator-category-extra',
  categoriesWebsite: COREAPI + 'category/collaborator', //web ctv
  createCategory: COREAPI + 'collaborator/new/create-category-node-v2', //website ctv
  getConfigWebsite: COREAPI + 'collaborator/new/all-website-config-v2', //web ctv
  checkDomainV2: COREAPI + 'collaborator/new/check-free-website-name', //website ctv
  createDomainFree: COREAPI + 'collaborator/new/create-free-website', //website ctv
  getProductsWebsite: COREAPI + 'product-v3', //web ctv
  listProductsForCollaboratorAddToWeb:
    COREAPI + 'collaborator/new/list-products-for-collaborator-add-to-web-v2', //web ctv
  addCategoryProducts: COREAPI + 'collaborator/new/add-category-products-v2', //web ctv
  getDetailProductWebsite: COREAPI + 'collaborator/product/', //web ctv
  registerDomain: COREAPI + 'collaborator/new/create-fee-website', //đăng ký tên miền có phí
  checkDomain: COREAPI + 'collaborator/new/check-fee-website-name', // kiểm tra tên miền có phí
  updateInfomationDomain:
    COREAPI + 'collaborator/new/create-user-info-to-registering-cost-domain', //cập nhật thông tin mua domain
  getUserWebsite: COREAPI + 'collaborator/new/user-website', //lay thong tin user
  addAllProductInCategoryToWeb:
    COREAPI + 'collaborator/new/add-category-all-products-v2',
  updateDisplayShip: COREAPI + 'collaborator/new/update-show-feeship',
  updatePriceForWebsite:
    COREAPI + 'collaborator/new/update-price-product-colla-website',
  resetPriceComeBackOriginal:
    COREAPI + 'collaborator/new/reset-price-product-colla-website',
  getListOrderExchange: COREAPI + 'collaborator/new/request-exchange-order',
  getPolicy: COREAPI + 'collaborator/new/policy',
  updateListProductsTypeCategory:
    COREAPI +
    'collaborator/new/update-collaborator-product-info-by-type-category',
  removeListProductsTypeCategory:
    COREAPI + 'collaborator/new/remove-colla-product-category-v2',
  updatePopUp: COREAPI + 'collaborator/new/update-user-website-popup',
  getListPopUp: COREAPI + 'collaborator/new/get-user-website-popup',
  //add button
  getListButton: COREAPI + 'collaborator/new/get-website-btn',
  updateButton: COREAPI + 'collaborator/new/update-website-btn',
  getListFilter: COREAPI + 'collaborator/new/system-website-filter',
  updateListFilter: COREAPI + 'collaborator/new/user-website-filter',

  //images slides
  getImagesSlide: COREAPI + 'collaborator/new/all-website-slide', //web ctv
  updateImagesSlide: COREAPI + 'collaborator/new/update-website-slide', //web ctv
  updateImagesSlideFromFlile:
    COREAPI + 'collaborator/new/update-image-for-website-slide-v2', //web ctv
  getImagesCategory: COREAPI + 'collaborator/new/all-website-category-image-v2',
  updateProductWebsite:
    COREAPI + 'collaborator/new/update-collaborator-product-info-v2', //web ctv
  updateConfigWebsite: COREAPI + 'collaborator/new/custom-website-config-v2', //web ctv
  updateInfoConfigWebsite: COREAPI + 'collaborator/new/custom-website-config', //web ctv
  removeProductWebsite:
    COREAPI + 'collaborator/new/remove-colla-product-category', //web ctv
  updateInfoDelivery: API + 'collaborator/order/update/', //web ctv
  updateInfoDelivery2: API + 'collaborator/order/update-v2/', //web ctv
  confirmOrderFromCTV: API + 'collaborator/order/confirm/', //web ctv
  deleteCategoryWebsite:
    COREAPI + 'collaborator/new/remove-collaborator-category', //web ctv

  // connect ecommerce

  //lazada
  connectEcommerceLazada: COREAPI + 'lazada/login-status',
  getAllCategory: COREAPI + 'lazada/get-category-tree',
  getProductsForLazada: COREAPI + 'lazada/get-product-to-upload-v3',
  // createProductsToLazada: COREAPI + 'lazada/create-product-batch-v2',
  getProductsFromLazada: COREAPI + 'lazada/get-list-product-v2',
  deleteProductsFromLazada: COREAPI + 'lazada/remove-product-batch-v2',
  deleteAProductFromLazada: COREAPI + 'lazada/remove-product-v2',
  disconnectToLazada: COREAPI + 'lazada/disconnect-lazada-ecommere',
  createProductsToLazada:
    COREAPI + 'lazada/add-product-colla-website-to-lazada-batch',
  // getStatusSalesChanel: COREAPI + 'ecommerce/get-status',
  getStatusSalesChanel: COREAPI + 'ecommerce/get-status-v2',
  getDetailProductFromLazada: COREAPI + 'lazada/get-detail-product/',
  updateProductFromLazada: COREAPI + 'lazada/update-product-v2',
  hideProductFromLazadaBatch: COREAPI + 'lazada/update-status-batch-v2',
  hideProductFromLazada: COREAPI + 'lazada/update-status-product-v2',
  getListCategoryExist: COREAPI + 'lazada/get-colla-categories',
  getProductFromCategory: COREAPI + 'lazada/get-products-by-category',
  getLinkConnectLazada: COREAPI + 'lazada/get-link-connect-lazada',
  pushProductToLazada: COREAPI + 'lazada/create-product-v2',
  getStatusSyncOrderLazada: COREAPI + 'lazada/get-status-sync-order-lazada',
  updateStatusSyncOrderLazada:
    COREAPI + 'lazada/update-status-sync-order-lazada',

  //shopee new v2
  getListShopShopee: COREAPI + 'shopee/v2/get-list-shop',
  connectShopee: COREAPI + 'shopee/v2/connect-shopee',
  disConnectShopee: COREAPI + 'shopee/v2/disconnect-shop',
  previewProductShopee: COREAPI + 'shopee/v2/preview-data-product-shopee',
  addProductToShopee: COREAPI + 'shopee/v2/add-product-shopee',
  getListProductShopee: COREAPI + 'shopee/v2/get-list-shopee-product',
  unSyncShopeeProduct: COREAPI + 'shopee/v2/unsync-shopee-product',
  getShopInfoShopee: COREAPI + 'shopee/v2/get-shop-info',
  updateInfoProductShopee: COREAPI + 'shopee/v2/update-shopee-product',
  updatePriceProductShopee: COREAPI + 'shopee/v2/update-shopee-product-price',
  getListOrderShopee: COREAPI + 'shopee/v2/get-list-shopee-order',
  uploadImageProductToShopee: COREAPI + 'shopee/v2/upload-product-image',
  getChannelLogistic: COREAPI + 'shopee/v2/get-channel-logistic',
  updateChannelLogistic: COREAPI + 'shopee/v2/update-channel-logistic',

  //lazada new v2
  connectLazada: COREAPI + 'lazada/v2/get-link-connect-lazada',
  getListShopLazada: COREAPI + 'lazada/v2/get-list-shop-info',
  previewProductLazada: COREAPI + 'lazada/v2/preview-data-lazada-product',
  addProductToLazada: COREAPI + 'lazada/v2/add-lazada-product',
  uploadImageProductToLazada: COREAPI + 'lazada/v2/upload-product-image',
  getListProductLazada: COREAPI + 'lazada/v2/get-list-lazada-product',
  unSyncLazadaProduct: COREAPI + 'lazada/v2/unsync-lazada-product',
  updateInfoProductLazada: COREAPI + 'lazada/v2/update-lazada-product',
  updatePriceProductLazada: COREAPI + 'lazada/v2/update-price-lazada-product',
  getListOrderLazada: COREAPI + 'lazada/v2/get-list-lazada-order',

  // connectEcommerceShopee: COREAPI + 'shopee/get-connect-link',
  getInfoShopFromShopee: COREAPI + 'shopee/get-shop-info',
  getAllCategoryFromShopee: COREAPI + 'shopee/get-categories',
  deleteSingleProductFromShopee: COREAPI + 'shopee/remove-product',
  deleteProductsFromShopee: COREAPI + 'shopee/remove-product-batch',
  getDetailProductFromShopee: COREAPI + 'shopee/get-product-detail',
  updateProductFromShopee: COREAPI + 'shopee/update-product',
  getListProductFromShopee: COREAPI + 'shopee/get-products-v3',
  getProductUpToShopee: COREAPI + 'shopee/get-product-to-upload-v4',
  createCategoryFromShopee: COREAPI + 'shopee/add-shop-category',
  getShopCategory: COREAPI + 'shopee/get-shop-categories',
  addProductToCategory: COREAPI + 'shopee/add-items-to-category',
  getCategoryProduct: COREAPI + 'shopee/get-category-products',
  getItemAddCategory: COREAPI + 'shopee/get-item-add-category',
  addProductsBeshopToShopee: COREAPI + 'shopee/add-product-batch',
  disconnectToShopee: COREAPI + 'shopee/disconnect-shopee',
  upEachProductToShopee: COREAPI + 'shopee/add-product-v2',
  PushProductToShopee: COREAPI + 'shopee/boost-products',
  pickUpAddress: COREAPI + 'shopee/get-address-pickup',
  getLogisticShopee: COREAPI + 'shopee/get-shop-logistics',
  updateLogisticShopee: COREAPI + 'shopee/update-shop-logistics',
  updatePriceForShopee: COREAPI + 'shopee/update-price-product-colla-shopee',
  getStatusSyncOrderShopee: COREAPI + 'shopee/get-status-sync-order-shopee',
  updateStatusSyncOrderShopee:
    COREAPI + 'shopee/update-status-sync-order-shopee',
  addProductsFromShopee:
    COREAPI + 'shopee/add-product-colla-website-to-shopee-batch',
  getUserShopeeConfig: COREAPI + 'shopee/get-user-shopee-config',
  setShopeeDescription: COREAPI + 'shopee/set-shopee-description',
  updateShopeeHoliday: COREAPI + 'shopee/update-shopee-holiday',

  getProductsFromWebCTVUploadTMDT:
    COREAPI + 'ecommerce/get-products-collaborator-website-v2',

  //api about template
  getTemplateWebsite: COREAPI + 'get-templates',
  changeTemplateWebsite: COREAPI + 'change-template',
  getLinkPreviewTemplateWebsite: COREAPI + 'preview-template',

  //get app key
  getAppKey: COREAPI + 'get-app-key',

  editPriceUsingExportProduct:
    COREAPI + 'collaborator/new/get-file-update-price-product-colla-website',
  editPriceUsingImportProduct:
    COREAPI + 'collaborator/new/file-update-price-product-colla-website',
};
// http://demoapi.nhanhre.vn
// api nestjs
const REACT_APP_CORE_API = process.env.REACT_APP_CORE_API;
const REACT_APP_NESTJS_CORE_API = process.env.REACT_APP_NESTJS_CORE_API;
export const newApi = {
  getListShopShopee: REACT_APP_NESTJS_CORE_API + 'shopee/get-list-shop',
  getShopInfoShopee: REACT_APP_NESTJS_CORE_API + 'shopee/get-shop-info',
  connectShopee: REACT_APP_NESTJS_CORE_API + 'shopee/connect',
  disconnectToShopee: REACT_APP_NESTJS_CORE_API + 'shopee/disconnect',
  updateNewPassword:
    REACT_APP_NESTJS_CORE_API + 'auth/set-new-require-password',

  loginNestjs: REACT_APP_NESTJS_CORE_API + 'auth/login',
  // PANCAKE
  postPancakeApiKey: REACT_APP_NESTJS_CORE_API + 'pancake/api-key',

  postPancakeProdcutSync: REACT_APP_NESTJS_CORE_API + 'pancake/products/sync',

  getPancakeApiKey: REACT_APP_NESTJS_CORE_API + 'pancake/api-key',

  getPancakeshop: REACT_APP_NESTJS_CORE_API + 'pancake/shops',
};

export default api;
