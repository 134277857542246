import React, { lazy } from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { CHECK_OWNER_SHOP } from './commons/constants';
import Route from './components/Route';
import ScrollToTop from './components/ScrollToTop';

//layout
const DefaultLayout = lazy(() =>
  import('./components/Layout/defaultLayout_v2'),
);

const updatePasswordLayout = lazy(() =>
  import('./components/Layout/UpdatePasswordLayout.js'),
);
const ManageLayout_v2 = lazy(() =>
  import('./components/Layout/ManageLayout_v2'),
);
const WebconfigLayout = lazy(() =>
  import('./components/Layout/webSiteConfig/index'),
);
const TemplateWebLayout = lazy(() =>
  import('./components/Layout/TemplateWebsite'),
);
//products
const ProductsPrivateOfShop = lazy(() =>
  import('./pages/ProductsPrivateOfShop'),
);
const ProductsVip = lazy(() => import('./pages/ProductsVip'));
const ProductsRequireAndVote = lazy(() =>
  import('./pages/ProductsRequireAndVote'),
);

const ListProductRequire = lazy(() =>
  import('./pages/Manage/ManageProductRequire/ListProductRequire'),
);
const DetailProductRequire = lazy(() =>
  import('./pages/Manage/ManageProductRequire/DetailRequire'),
);

const DownloadLayout = lazy(() =>
  import('./components/Layout/downloadLayout_v2'),
);
const orderCreate = lazy(() => import('./pages/Manage/Order/Create/')); //had loading
const Discount_v2 = lazy(() =>
  import('./pages/Manage/Discount_v2/Discount_v2'),
); // had loading
const AccountBank = lazy(() =>
  import('./pages/Manage/Discount_v2/AccountBank/AccountBank'),
); // had loading
const Search = lazy(() => import('./pages/Search_v2')); //not use
const CategoryPrivate = lazy(() => import('./pages/SubCategory_v2Private'));
const orderDetail = lazy(() =>
  import('./pages/Manage/Order/Detail/detailOrder'),
); //had loading
const oldOrderDetail = lazy(() =>
  import('./pages/Manage/Order/Detail/DetailOldOrder'),
);
const widthDraw = lazy(() => import('./pages/Manage/WithDraw/WithDraw')); // had loading
const list = lazy(() => import('./pages/Manage/Order/List/List')); // had loading
const ListOrderExchangeAndReturn = lazy(() =>
  import('./pages/Manage/Order/ListOrderExchangeAndReturn'),
);
const Download = lazy(() => import('./pages/Download_v2/download')); //had loading
const Guide = lazy(() => import('./components/GuideLayout/guide'));
const Guide_Register = lazy(() => import('./pages/Guide/Guide_Register'));
const guideRegisterSuccess = lazy(() =>
  import('./pages/Guide/Guide_Register/registerSuccess'),
);
const Guide_HowToWork = lazy(() => import('./pages/Guide/Guide_HowToWork'));
const Guide_Question = lazy(() => import('./pages/Guide/Guide_question/index'));
const Guide_DownImage = lazy(() =>
  import('./pages/Guide/Guide_Guide/DownImage/index'),
);
const Guide_Create = lazy(() =>
  import('./pages/Guide/Guide_Guide/Create/index'),
);
const Guide_Manage = lazy(() =>
  import('./pages/Guide/Guide_Guide/Manage/index'),
);
const Guide_WithDraw = lazy(() =>
  import('./pages/Guide/Guide_Guide/WithDraw/index'),
);
const Guide_YourWeb = lazy(() =>
  import('./pages/Guide/Guide_Guide/YourWeb/index'),
);
const DetailProduct = lazy(() => import('./pages/DetailProduct_v2/detail'));
const PageDetailProductRequire = lazy(() =>
  import('./pages/PageDetailProductRequire'),
);
const DetailProductVote = lazy(() => import('./pages/Vote'));
const ManageVote = lazy(() => import('./pages/Manage/Vote'));
const SeeAll = lazy(() => import('./pages/SeeAll'));
const Category = lazy(() => import('./pages/Category_v2'));
const Home_v2 = lazy(() => import('./pages/Home_v2'));
const NotFound = lazy(() => import('./pages/Notfound')); //re-coding if you can love you so much
const Authentication = lazy(() => import('./pages/Authentication'));
const InfoAccount = lazy(() => import('./pages/Manage/InfoAccount'));
const CareProduct = lazy(() => import('./pages/Manage/CareProduct'));
const UpdateOrder = lazy(() =>
  import('./pages/Manage/Notification/UpdateOrder'),
);
const UpdateOrderShopee = lazy(() =>
  import('./pages/Manage/Notification/UpdateOrderShopee'),
);
const InfoPolicy = lazy(() => import('./pages/Manage/Notification/Policy'));
const UpdateBalance = lazy(() =>
  import('./pages/Manage/Notification/UpdateBalance'),
);
const ProgramSupport = lazy(() =>
  import('./pages/Manage/Notification/ProgramSupport'),
);
const SysNotification = lazy(() =>
  import('./pages/Manage/Notification/SysNotification'),
);
const OrderReturn = lazy(() => import('./pages/Manage/Order/Return'));
const Partner = lazy(() => import('./pages/Manage/Order/Create/Partner'));
const Deposit = lazy(() => import('./pages/Manage/Deposit'));
const NotificationTransaction = lazy(() =>
  import('./pages/Manage/Notification/UpdateTransaction'),
);
const ManagePolicy = lazy(() => import('./pages/Manage/PolicyManage'));
const ManageStaff = lazy(() => import('./pages/Manage/ManageStaff'));
const HistoryStaff = lazy(() =>
  import('./pages/Manage/ManageStaff/HistoryStaff'),
);
const SalesAnalysis = lazy(() => import('./pages/Manage/SalesAnalysis'));

const RegisterDomain = lazy(() =>
  import('./pages/Manage/Website/RegisterDomain'),
);
const ManageWholeSaleCustomer = lazy(() =>
  import('./pages/Manage/ManageWholeSaleCustomer'),
);
const ManagerCategoryWeb = lazy(() =>
  import('./pages/Manage/Website/ManagerCategory'),
);
const GalleryWebsite = lazy(() =>
  import('./pages/Manage/Website/GalleryWebsite'),
);
const SlideImage = lazy(() => import('./pages/Manage/Website/SlideImage'));
const addProductWeb = lazy(() => import('./pages/Manage/Website/addProduct'));
const ChooseProductForWeb = lazy(() =>
  import('./pages/Manage/Website/addProduct/ChooseProduct'),
);
const DetailProductForWeb = lazy(() =>
  import('./pages/Manage/Website/Template/default/detailProduct'),
);
const DetailProductForSalesChanel = lazy(() =>
  import('./pages/Manage/Website/SalesChanel/detailProduct'),
);
const UploadCategoryImage = lazy(() =>
  import('./pages/Manage/Website/CategoryImage'),
);
const UploadChildCategoryImage = lazy(() =>
  import('./pages/Manage/Website/ChildCategoryImage'),
);
const InfoWebsiteCTV = lazy(() =>
  import('./pages/Manage/Website/UpdateInfoWebsite'),
);
const UpdatePolicy = lazy(() => import('./pages/Manage/Website/UpdatePolicy'));
const UpdateFeeShip = lazy(() =>
  import('./pages/Manage/Website/UpdateFeeShip'),
);
const UploadProducts = lazy(() =>
  import('./pages/Manage/Website/uploadProducts'),
);
const InputInfoRegisterDomain = lazy(() =>
  import('./pages/Manage/Website/RegisterDomain/InputInfoDomain'),
);
const Policy = lazy(() => import('./pages/Policy/index'));
const SalesChanel = lazy(() => import('./pages/Manage/Website/SalesChanel'));
const SalesChanelLazada = lazy(() =>
  import('./pages/Manage/SalesChanel/Lazada'),
);
const SalesChanelShopee = lazy(() =>
  import('./pages/Manage/SalesChanel/Shopee'),
);
const ManageShopShopee = lazy(() =>
  import('./pages/Manage/SalesChanel/Shopee/ManageShopee'),
);
const ManageShopLazada = lazy(() =>
  import('./pages/Manage/SalesChanel/Lazada/ManageLazada'),
);
const ManagePancake = lazy(() =>
  import('./pages/Manage/SalesChanel/Pancake/ManagePancake'),
);
const UpdateSalePrice = lazy(() =>
  import('./pages/Manage/Website/UpdateSalePrice'),
);
const ProductsOfCategory = lazy(() =>
  import(
    './pages/Manage/Website/SalesChanel/ManageCategories/ProductOfCategory'
  ),
);
const StoreImages = lazy(() => import('./pages/Manage/StoreImages'));
const AddProductFromListProductSearch = lazy(() =>
  import('./pages/Manage/Website/addProduct/ListProductSearch'),
);
const GetAppKey = lazy(() => import('./pages/Manage/AppKey'));
const ManageFlashSale = lazy(() =>
  import('./pages/Manage/Website/ManageFlashSale'),
);
const CreatePopUp = lazy(() => import('./pages/Manage/Website/CreatePopUp'));
const CreateButton = lazy(() => import('./pages/Manage/Website/CreateButton'));

// template
const DetailProductWebCTV = lazy(() =>
  import('./pages/Manage/Website/Template/ConfigWebsite/detail.js'),
);
const CategoryProductsWebCTV = lazy(() =>
  import('./pages/Manage/Website/Template/ConfigWebsite/category.js'),
);
const StockBanerCategory = lazy(() =>
  import('./pages/Manage/Website/Template/ConfigWebsite/bannerCategory.js'),
);
const SearchProductsWebCTV = lazy(() =>
  import('./pages/Manage/Website/Template/ConfigWebsite/search'),
);
const ConfigWebsite = lazy(() =>
  import('./pages/Manage/Website/Template/ConfigWebsite/home.js'),
);

//owner shop
const LoginOwnerShop = lazy(() => import('./pages/LoginOwnerShop'));

//dashboard
const DashboardShopee = lazy(() =>
  import('./pages/Manage/SalesChanel/Shopee/ManageShopee/Dashboard'),
);

const DashboardLazada = lazy(() =>
  import('./pages/Manage/SalesChanel/Lazada/ManageLazada/Dashboard'),
);

const ManageProductShopee = lazy(() =>
  import(
    './pages/Manage/SalesChanel/Shopee/ManageShopee/Dashboard/Feature/ManageProduct'
  ),
);

const ManageProductLazada = lazy(() =>
  import(
    './pages/Manage/SalesChanel/Lazada/ManageLazada/Dashboard/Feature/ManageProduct'
  ),
);

const AsyncOrderShopee = lazy(() =>
  import(
    './pages/Manage/SalesChanel/Shopee/ManageShopee/Dashboard/Feature/AsyncOrder'
  ),
);

const AsyncOrderLazada = lazy(() =>
  import(
    './pages/Manage/SalesChanel/Lazada/ManageLazada/Dashboard/Feature/AsyncOrder'
  ),
);

const ManageCategogyShopee = lazy(() =>
  import(
    './pages/Manage/SalesChanel/Shopee/ManageShopee/Dashboard/Feature/ManageCategory'
  ),
);

const ManageTransportShopee = lazy(() =>
  import(
    './pages/Manage/SalesChanel/Shopee/ManageShopee/Dashboard/Feature/ManageTransport'
  ),
);

const ManageReturnOrderShopee = lazy(() =>
  import(
    './pages/Manage/SalesChanel/Shopee/ManageShopee/Dashboard/Feature/ManageReturnOrder'
  ),
);

const ProductProfitList = lazy(() => import('./pages/Manage/ProductProfit'));
const DetailProductProfit = lazy(() =>
  import('./pages/Manage/ProductProfit/DetailProfit'),
);
const TransactionProfit = lazy(() =>
  import('./pages/Manage/ProductProfit/TransactionProfit'),
);

//manage list product 2023
const ListProduct = lazy(() =>
  import('./pages/Manage/ManageListProduct/ListProduct'),
);
const ListInStock = lazy(() =>
  import('./pages/Manage/ManageListProduct/InStock'),
);
const Processing = lazy(() =>
  import('./pages/Manage/ManageListProduct/Processing'),
);
const GetInStockByProductCode = lazy(() =>
  import('./pages/Manage/ManageListProduct/InStockByProductCode'),
);
const getDetailStock = lazy(() =>
  import('./pages/Manage/ManageListProduct/DetailStock'),
);
const GetDetailSold = lazy(() =>
  import('./pages/Manage/ManageListProduct/DetailProductSold'),
);
const UpdatePassword = lazy(() =>
  import('./pages/Login/components/UpdatePassword/index.js'),
);
const routes = (
  <Router>
    <ScrollToTop>
      <Switch>
        <Route
          path="/"
          exact
          component={CHECK_OWNER_SHOP ? list : Home_v2}
          layout={CHECK_OWNER_SHOP ? ManageLayout_v2 : DefaultLayout}
          // syncVote={true}
        />
        {/* authentication */}
        <Route
          path="/register"
          exact
          component={Authentication}
          layout={DefaultLayout}
        />
        <Route
          path="/policy"
          exact
          component={Policy}
          //  layout={DefaultLayout}
        />
        <Route
          path="/login"
          exact
          isAuthenticate
          component={Home_v2}
          layout={DefaultLayout}
        />
        <Route
          path="/login-owner-shop"
          exact
          //  isAuthenticate
          component={LoginOwnerShop}
          //  layout={DefaultLayout}
        />
        <Route
          path="/old-order/detail/:id"
          exact
          isAuthenticate
          component={oldOrderDetail}
          layout={ManageLayout_v2}
        />
        <Route
          path="/order/detail/:id"
          exact
          component={orderDetail}
          layout={ManageLayout_v2}
        />
        <Route
          path="/sales-chanel/:id/category/:idCategory"
          exact
          isAuthenticate
          component={ProductsOfCategory}
          layout={ManageLayout_v2}
        />
        <Route
          path="/sales-chanel/lazada"
          exact
          isAuthenticate
          component={SalesChanelLazada}
          layout={ManageLayout_v2}
        />
        <Route
          path="/sales-chanel/shopee"
          exact
          isAuthenticate
          component={SalesChanelShopee}
          layout={ManageLayout_v2}
        />
        <Route
          path="/sales-chanel/manage-shop-shopee"
          exact
          isAuthenticate
          component={ManageShopShopee}
          layout={ManageLayout_v2}
        />
        <Route
          path="/sales-chanel/manage-shop-lazada"
          exact
          isAuthenticate
          component={ManageShopLazada}
          layout={ManageLayout_v2}
        />

        <Route
          path="/sales-chanel/manage-shop-pancake"
          exact
          isAuthenticate
          component={ManagePancake}
          layout={ManageLayout_v2}
        />
        <Route
          path="/sales-chanel/manage-shop-shopee/dasdboard/list-product/:id/:shopeeId"
          exact
          isAuthenticate
          component={ManageProductShopee}
          layout={DashboardShopee}
        />

        <Route
          path="/sales-chanel/manage-shop-lazada/dasdboard/list-product/:id/:lazadaId"
          exact
          isAuthenticate
          component={ManageProductLazada}
          layout={DashboardLazada}
        />

        <Route
          path="/sales-chanel/manage-shop-shopee/dasdboard/order/:id/:shopeeId"
          exact
          isAuthenticate
          component={AsyncOrderShopee}
          layout={DashboardShopee}
        />

        <Route
          path="/sales-chanel/manage-shop-lazada/dasdboard/order/:id/:lazadaId"
          exact
          isAuthenticate
          component={AsyncOrderLazada}
          layout={DashboardLazada}
        />

        <Route
          path="/sales-chanel/manage-shop-shopee/dasdboard/category/:id/:shopeeId"
          exact
          isAuthenticate
          component={ManageCategogyShopee}
          layout={DashboardShopee}
        />
        <Route
          path="/sales-chanel/manage-shop-shopee/dasdboard/transport/:id/:shopeeId"
          exact
          isAuthenticate
          component={ManageTransportShopee}
          layout={DashboardShopee}
        />
        <Route
          path="/sales-chanel/manage-shop-shopee/dasdboard/return-order/:id/:shopeeId"
          exact
          isAuthenticate
          component={ManageReturnOrderShopee}
          layout={DashboardShopee}
        />

        <Route
          path="/sales-chanel/:nameChanel/:id"
          exact
          isAuthenticate
          component={DetailProductForSalesChanel}
          layout={ManageLayout_v2}
        />
        <Route
          path="/discount/deposit"
          exact
          isAuthenticate
          component={Deposit}
          layout={ManageLayout_v2}
        />
        <Route
          path="/products-private-of-shop"
          exact
          isAuthenticate
          component={ProductsPrivateOfShop}
          layout={DefaultLayout}
        />
        <Route
          path="/product-vip"
          exact
          isAuthenticate
          component={ProductsVip}
          layout={DefaultLayout}
        />
        <Route
          path="/product-require-and-vote"
          exact
          isAuthenticate
          component={ProductsRequireAndVote}
          layout={DefaultLayout}
        />
        <Route
          path="/list-product-require"
          exact
          isAuthenticate
          component={ListProductRequire}
          layout={ManageLayout_v2}
        />
        <Route
          path="/detail-require"
          exact
          isAuthenticate
          component={DetailProductRequire}
          layout={ManageLayout_v2}
        />
        <Route
          path="/order/create"
          exact
          isAuthenticate
          component={orderCreate}
          layout={ManageLayout_v2}
        />
        <Route
          path="/order/create/partner"
          exact
          isAuthenticate
          component={Partner}
          layout={ManageLayout_v2}
        />
        <Route
          path="/discount/withdraw"
          exact
          isAuthenticate
          component={widthDraw}
          layout={ManageLayout_v2}
        />
        <Route
          path="/order/list"
          exact
          isAuthenticate
          component={list}
          layout={ManageLayout_v2}
        />
        <Route
          path="/order/list-order-exchange-and-return"
          exact
          isAuthenticate
          component={ListOrderExchangeAndReturn}
          layout={ManageLayout_v2}
        />
        <Route
          path="/order/return"
          exact
          isAuthenticate
          component={OrderReturn}
          layout={ManageLayout_v2}
        />
        <Route
          path="/order/return/:id"
          exact
          isAuthenticate
          component={OrderReturn}
          layout={ManageLayout_v2}
        />
        <Route
          path="/profit"
          exact
          isAuthenticate
          component={Discount_v2}
          layout={ManageLayout_v2}
        />
        <Route
          path="/discount/accountbank"
          exact
          isAuthenticate
          component={AccountBank}
          layout={ManageLayout_v2}
        />
        {/* info account*/}
        <Route
          path="/info/account"
          exact
          isAuthenticate
          component={InfoAccount}
          layout={ManageLayout_v2}
        />
        {/* care products */}
        <Route
          path="/products-care"
          exact
          isAuthenticate
          component={CareProduct}
          layout={ManageLayout_v2}
        />
        {/* manage vote */}
        <Route
          path="/manage/:vote"
          exact
          isAuthenticate
          syncVote={true}
          component={ManageVote}
          layout={ManageLayout_v2}
        />
        {/* notification */}
        <Route
          path="/notification/order"
          exact
          isAuthenticate
          component={UpdateOrder}
          layout={ManageLayout_v2}
        />
        <Route
          path="/notification/order-shopee"
          exact
          isAuthenticate
          component={UpdateOrderShopee}
          layout={ManageLayout_v2}
        />
        <Route
          path="/notification/policy"
          exact
          isAuthenticate
          component={InfoPolicy}
          layout={ManageLayout_v2}
        />
        <Route
          path="/notification/transaction"
          exact
          isAuthenticate
          component={NotificationTransaction}
          layout={ManageLayout_v2}
        />
        <Route
          path="/manage-policy"
          exact
          component={ManagePolicy}
          layout={ManageLayout_v2}
        />
        <Route
          path="/list-staff"
          exact
          component={ManageStaff}
          layout={ManageLayout_v2}
        />
        <Route
          path="/history-staff"
          exact
          component={HistoryStaff}
          layout={ManageLayout_v2}
        />
        <Route
          path="/manage-wholesale-customer"
          exact
          component={ManageWholeSaleCustomer}
          layout={ManageLayout_v2}
        />
        <Route
          path="/sales-analysis"
          exact
          component={SalesAnalysis}
          layout={ManageLayout_v2}
        />
        <Route
          path="/store-images"
          exact
          component={StoreImages}
          layout={ManageLayout_v2}
        />
        <Route
          path="/notification/balance"
          exact
          isAuthenticate
          component={UpdateBalance}
          layout={ManageLayout_v2}
        />
        <Route
          path="/notification/support"
          exact
          isAuthenticate
          component={ProgramSupport}
          layout={ManageLayout_v2}
        />
        <Route
          path="/notification/system"
          exact
          isAuthenticate
          component={SysNotification}
          layout={ManageLayout_v2}
        />
        {/* Guide */}
        <Route path="/guide" exact component={Guide_Register} layout={Guide} />
        {/* Guide - register */}
        <Route
          path="/guide/registers"
          exact
          component={Guide_Register}
          layout={Guide}
        />
        {/* Guide - register */}
        <Route
          path="/guide/register/success"
          exact
          component={guideRegisterSuccess}
          layout={Guide}
        />
        {/* Guide - register */}
        <Route
          path="/guide/howtowork"
          exact
          component={Guide_HowToWork}
          layout={Guide}
        />
        {/* Guide - question */}
        <Route
          path="/guide/question"
          exact
          component={Guide_Question}
          layout={Guide}
        />
        {/* Guide - Guide - DownImage */}
        <Route
          path="/guide/guide/downimage"
          exact
          component={Guide_DownImage}
          layout={Guide}
        />
        {/* Guide - Guide - WithDraw */}
        <Route
          path="/guide/guide/withdraw"
          exact
          component={Guide_WithDraw}
          layout={Guide}
        />
        {/* Guide - Guide - Manage */}
        <Route
          path="/guide/guide/manage"
          exact
          component={Guide_Manage}
          layout={Guide}
        />
        {/* Guide - Guide - Create */}
        <Route
          path="/guide/guide/create"
          exact
          component={Guide_Create}
          layout={Guide}
        />
        {/* Guide - Guide - YourWeb */}
        <Route
          path="/guide/guide/yourweb"
          exact
          component={Guide_YourWeb}
          layout={Guide}
        />
        {/* product - detail*/}
        <Route
          path="/product/detail/:id"
          exact
          component={DetailProduct}
          layout={DefaultLayout}
        />

        <Route
          path="/product/detail-product-require/:id"
          exact
          component={PageDetailProductRequire}
          layout={DefaultLayout}
        />
        {/* product - detail - voting*/}
        <Route
          path="/product-vote/detail/:id"
          exact
          syncVote={true}
          component={DetailProductVote}
          layout={DefaultLayout}
        />
        {/* See All*/}
        {/* <Route
          path="/see-all/:filter"
          exact
          component={SeeAll}
          layout={DefaultLayout}
        /> */}
        <Route
          path={'/category/:catname/:id'}
          exact
          component={Category}
          layout={DefaultLayout}
        />
        <Route
          path={'/category-private/:catname/:id'}
          exact
          component={CategoryPrivate}
          layout={DefaultLayout}
        />
        <Route
          path="/downloads"
          exact
          component={Download}
          layout={DownloadLayout}
        />
        {/* website */}
        <Route
          path="/website/update-sale-price"
          exact
          isAuthenticate
          component={UpdateSalePrice}
          layout={ManageLayout_v2}
        />
        <Route
          path="/website/get-app-key"
          exact
          isAuthenticate
          component={GetAppKey}
          layout={ManageLayout_v2}
        />
        <Route
          path="/website/manage-flash-sale"
          exact
          isAuthenticate
          component={ManageFlashSale}
          layout={ManageLayout_v2}
        />
        <Route
          path="/website/create-pop-up"
          exact
          isAuthenticate
          component={CreatePopUp}
          layout={ManageLayout_v2}
        />
        <Route
          path="/website/create-button"
          exact
          isAuthenticate
          component={CreateButton}
          layout={ManageLayout_v2}
        />
        <Route
          path="/website/register-domain"
          exact
          isAuthenticate
          component={RegisterDomain}
          layout={ManageLayout_v2}
        />
        <Route
          path="/website/input-info-register-domain"
          exact
          isAuthenticate
          component={InputInfoRegisterDomain}
          layout={ManageLayout_v2}
        />
        <Route
          path="/website/upload-products" //chi co giao dien chua co logic
          exact
          isAuthenticate
          component={UploadProducts}
          layout={ManageLayout_v2}
        />
        <Route
          path="/website/info-website"
          exact
          isAuthenticate
          component={InfoWebsiteCTV}
          layout={ManageLayout_v2}
        />
        <Route
          path="/website/update-policy"
          exact
          isAuthenticate
          component={UpdatePolicy}
          layout={ManageLayout_v2}
        />
        {/* <Route
          path="/website/manager-category"
          exact
          isAuthenticate
          component={ManagerCategoryWeb}
          layout={WebconfigLayout}
        /> */}
        {/* <Route
          path="/website/config-website"
          exact
          isAuthenticate
          component={ConfigWebsite}
          layout={WebconfigLayout}
        /> */}
        <Route
          path="/website/config-website"
          exact
          isAuthenticate
          component={ConfigWebsite}
          layout={TemplateWebLayout}
        />
        <Route
          path="/website/gallery-website"
          exact
          isAuthenticate
          component={GalleryWebsite}
          layout={WebconfigLayout}
        />
        <Route
          path="/website/config-slide"
          exact
          isAuthenticate
          component={SlideImage}
          layout={WebconfigLayout}
        />
        <Route
          path="/website/add-product/:id"
          exact
          isAuthenticate
          component={addProductWeb}
          layout={WebconfigLayout}
        />
        <Route
          path="/website/choose-product"
          exact
          isAuthenticate
          component={ChooseProductForWeb}
          layout={WebconfigLayout}
        />
        <Route
          path="/website/add-product/:salesName/choose-product-from-search"
          exact
          isAuthenticate
          component={AddProductFromListProductSearch}
          layout={WebconfigLayout}
        />
        <Route
          path="/sale-chanel/choose-product"
          exact
          isAuthenticate
          component={ChooseProductForWeb}
          layout={WebconfigLayout}
        />
        <Route
          path="/add-products"
          exact
          isAuthenticate
          component={ChooseProductForWeb}
          layout={WebconfigLayout}
        />
        <Route
          path="/add-products-to-sales-chanel"
          exact
          isAuthenticate
          component={ChooseProductForWeb}
          layout={WebconfigLayout}
        />
        {/* <Route
          path='/website/detail-product/:id'
          exact
          component={DetailProductForWeb}
          layout={WebconfigLayout}
        /> */}
        {/* template web01 */}
        <Route
          path="/website/detail-product/:id"
          exact
          component={DetailProductWebCTV}
          layout={TemplateWebLayout}
        />
        <Route
          path="/website/manage-category"
          exact
          component={CategoryProductsWebCTV}
          layout={TemplateWebLayout}
        />

        <Route
          path="/website/manage-category/banner/:id"
          exact
          component={StockBanerCategory}
          layout={WebconfigLayout}
        />
        <Route
          path="/website/search"
          exact
          component={SearchProductsWebCTV}
          layout={TemplateWebLayout}
        />
        <Route
          path="/website/update-fee-ship"
          exact
          component={UpdateFeeShip}
          layout={WebconfigLayout}
        />
        <Route
          path="/website/upload-category-image/:id"
          exact
          component={UploadCategoryImage}
          layout={WebconfigLayout}
        />
        <Route
          path="/website/child-category-image/:id"
          exact
          component={UploadChildCategoryImage}
          layout={WebconfigLayout}
        />
        <Route
          path="/product-profit-list"
          exact
          component={ProductProfitList}
          layout={ManageLayout_v2}
        />
        <Route
          path="/detail-product-profit/:code"
          exact
          component={DetailProductProfit}
          layout={ManageLayout_v2}
        />
        <Route
          path="/detail-transaction-profit/:transaction_id"
          exact
          component={TransactionProfit}
          layout={ManageLayout_v2}
        />

        {/* manage list priduct */}
        <Route
          path="/manage-list-product"
          exact
          component={ListProduct}
          layout={ManageLayout_v2}
        />

        <Route
          path="/list-in-stock"
          exact
          component={ListInStock}
          layout={ManageLayout_v2}
        />

        <Route
          path="/detail-product-processing/:productCode"
          exact
          component={Processing}
          layout={ManageLayout_v2}
        />

        <Route
          path="/get-detail-instock-by-product-code/:productCode"
          exact
          component={GetInStockByProductCode}
          layout={ManageLayout_v2}
        />

        <Route
          path="/get-detail-stock/:productCode"
          exact
          component={getDetailStock}
          layout={ManageLayout_v2}
        />

        <Route
          path="/get-detail-sold/:productCode"
          exact
          component={GetDetailSold}
          layout={ManageLayout_v2}
        />
        <Route
          path="/update-password"
          exact
          component={UpdatePassword}
          layout={updatePasswordLayout}
        />
        <Route path="/search" exact component={Search} layout={DefaultLayout} />
        <Route path="*" exact component={NotFound} />
      </Switch>
    </ScrollToTop>
  </Router>
);

export default routes;
