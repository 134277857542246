import { action, observable } from 'mobx';

class ModalStore {
    @observable message = '';
    @observable show = false;
    @observable title = '';

    @action handleShow = (message='', link = '', title = '') => {
        this.message = message;
        this.show =  true;
        this.link = link;
        this.title = title;
    }

    @action handleHide = (message='', link = '', title = '') => {
        this.message = message;
        this.show =  false;
        this.link = link;
        this.title = title;
    }
}

export default ModalStore;