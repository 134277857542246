import * as types from '../../commons/constants';

let initialState = {
    productReturn: [],
    productAddAfterReturn: [],
    allProductsInOrder: [],
    priceOldOrder: 0,
    priceNewOrder: 0,
    priceExchangeOrder: '',
    priceShip: 0,
    clickButtonFinish: false,
    finishOrder: false,
    totalPrice: 0,
    infoOrderReturn: '',
    shareCountProduct: [],
    productExchangeOrder: [],
    reasonReturn: '',
    noteReasonReturn: null,
    collaboratorShip: null,
    percentDown: '',
}

let OrderReturn = (state = initialState, action) => {
    switch(action.type){
        case types.INFO_ORDER_RETURN:
            return {
                ...state,
                infoOrderReturn: action.infoOrderReturn
            }

        case types.REASON_RETURN:
            return {
                ...state,
                reasonReturn: action.reasonReturn
            }

        case types.NOTE_REASON_RETURN:
            return {
                ...state,
                noteReasonReturn: action.noteReasonReturn
            }

        case types.SHARE_COUNT_RETURN:
            return {
                ...state,
                shareCountProduct: action.shareCountProduct
            }

        case types.RETURN_PRODUCTS:
            return {
                ...state,
                productReturn: action.productReturn
            }

        case types.ADD_PRODUCTS_AFTER_RETURN:
            return {
                ...state,
                productAddAfterReturn: action.productAddAfterReturn
            }

        case types.PRODUCT_IN_ORDER_EXCHANGE:
            return {
                ...state,
                productExchangeOrder: action.productExchangeOrder
            }

        case types.ALL_PRODUCT_IN_ORDER:
            return {
                ...state,
                allProductsInOrder: action.allProductInOrder
            }

        case types.PRICE_OLD_ORDER_RETURN:
            return {
                ...state,
                priceOldOrder: action.priceOldOrder
            }

        case types.PRICE_NEW_ORDER_RETURN:
            return {
                ...state,
                priceNewOrder: action.priceNewOrder
            }

        case types.PRICE_NEW_ORDER:
            return {
                ...state,
                priceExchangeOrder: action.priceExchangeOrder
            }

        case types.PRICE_SHIP:
            return {
                ...state,
                priceShip: action.priceShip
            }

        case types.ACTION_BUTTON_FINISH:
            return {
                ...state,
                clickButtonFinish: action.finishOrderReturn
            }

        case types.FINISH_ORDER_RETURN:
            return {
                ...state,
                finishOrder: action.finishOrder
            }

        case types.TOTAL_PRICE_ORDER_RETURN:
            return {
                ...state,
                totalPrice: action.totalPrice
            }

        case types.COLLABORATOR_SHIP:
            return {
                ...state,
                collaboratorShip: action.collaboratorShip
            }

        case types.PERCENT_DOWN:
            return {
                ...state,
                percentDown: action.percentDown
            }

        default: return state
    }
}

export default OrderReturn;