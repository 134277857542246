import { action, observable } from 'mobx';

class AlertStore {
    @observable message = '';
    @observable hasError = false;
    @observable hasWarning = false;
    @observable hasSuccess = false;
    @observable show = false;
    @observable link = '';
    @observable function = '';
    @observable functionBtnCancel = '';
    @observable hasButtonCancel = false;


    @action ok = () => {
        this.show =  false;
        this.hasWarning = false;
        this.hasError = false;
        this.hasSuccess = false;
        this.message = '';
    }

    @action setHasButtonCancel = (hasButton = false) => {
        this.hasButtonCancel = hasButton;
    }

    @action success = (message, link = '',  func = '', funcBtnCancel = '') => { //need re-custom
        this.message = message;
        this.hasError = false;
        this.show =  true;
        this.hasSuccess = true;
        this.hasWarning = false;
        this.link = link;
        this.function = func;
        this.functionBtnCancel = funcBtnCancel;
    }

    @action error = (message, link = '', func = '', funcBtnCancel = '') => {
        this.message = message;
        this.hasError = true;
        this.show =  true;
        this.hasSuccess = false;
        this.hasWarning = false;
        this.link = link;
        this.function = func;
        this.functionBtnCancel = funcBtnCancel;
    }

    @action warning = (message, link = '',  func = '', funcBtnCancel = '') => {
        this.message = message;
        this.hasWarning = true;
        this.show =  true;
        this.hasError = false;
        this.hasSuccess = false;
        this.link = link;
        this.function = func;
        this.functionBtnCancel = funcBtnCancel;
    }

    @action reset = () => {
        this.message = '';
        this.show =  false;
        this.link = '';
        this.hasWarning = false;
        this.hasError = false;
        this.function = '';
        this.hasButtonCancel = false;
        this.hasSuccess = false;
        this.functionBtnCancel = '';

    }
}

export default AlertStore;